import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

import Slider from "react-slick"

export default function Banner() {
  const bannerImages = useStaticQuery(graphql`
    fragment desktopSize on File {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 70) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    query {
      images: allFile(
        filter: { relativeDirectory: { eq: "banners" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            ...desktopSize
          }
        }
      }
    }
  `)

  return (
    <section className="banner">
      <Slider dots autoplaySpeed={3000}>
        {bannerImages.images.edges.map(banner => (
          <Img objectFit="cover" fluid={banner.node.childImageSharp.fluid} />
        ))}
      </Slider>
    </section>
  )
}
