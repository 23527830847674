import React from "react"

import PropTypes from "prop-types"

export default function Title({ label, pre, pos, theme }) {
  return (
    <h1 className={`title ${theme || "light"} separator`}>
      {pre && <span className="title-pre">{pre}</span>}
      {label}
      {pos && <span className="title-pos">{pos}</span>}
    </h1>
  )
}

Title.propTypes = {
  label: PropTypes.string.isRequired,
  pre: PropTypes.string,
  theme: PropTypes.string,
  pos: PropTypes.string,
}

Title.defaultProps = {
  pre: null,
  pos: null,
  theme: "light",
}
