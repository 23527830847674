import React, { useCallback } from "react"
import Title from "./title"
import { graphql, useStaticQuery } from "gatsby"
import Slider from "react-slick"
import Img from "gatsby-image"

export default function Customers() {
  const logos = useCallback(
    useStaticQuery(graphql`
      query {
        allFile(
          filter: { relativeDirectory: { eq: "clientes" } }
          sort: { fields: name }
        ) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 205, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `),
    []
  )

  const galleryResponsive = [
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
      },
    },
  ]

  const setupThumb = (fluid, index) => (
    <div className="customer-thumb">
      <Img fluid={fluid} objectFit="cover" />
    </div>
  )

  return (
    <section id="clientes-atendidos" className="customers">
      <div className="container">
        <div className="columns">
          <div className="column has-text-centered">
            <Title label="Clientes" theme="light" />
          </div>
        </div>

        <div className="columns is-centered">
          <div className="column is-10">
            <Slider
              slidesToShow={5}
              slidesToScroll={5}
              responsive={galleryResponsive}
            >
              {logos.allFile.edges.map((image, index) =>
                setupThumb(image.node.childImageSharp.fluid, index)
              )}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  )
}
