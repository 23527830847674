import React, { useState, useRef } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import Services from "../components/services"
import Gallery from "../components/gallery"
import Customers from "../components/customers"
import About from "../components/about"
import Contact from "../components/contact"
import Whatsapp from "../components/Whatsapp"

import IndexContext from "../components/context"

export default function () {
  const [galleryActive, setGalleryActive] = useState(
    "coberturaPolicarbonatoCompacto"
  )

  const [galleryActiveLabel, setGalleryActiveLabel] = useState(
    "Cobertura em Policarbonato Compacto"
  )

  const galleryRef = useRef(null)

  return (
    <Layout>
      <IndexContext.Provider
        value={{
          galleryRef,
          galleryActive,
          setGalleryActive,
          galleryActiveLabel,
          setGalleryActiveLabel,
        }}
      >
        <SEO title="Home" />
        <Banner />
        <Services />
        <Gallery />
        <Customers />
        <About />
        <Contact />
        <Whatsapp />
      </IndexContext.Provider>
    </Layout>
  )
}
