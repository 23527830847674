import React, { useContext } from "react"

import IndexContext from "../components/context"

export default function ServiceList() {
  const { setGalleryActive, setGalleryActiveLabel, galleryRef } = useContext(
    IndexContext
  )

  const services = [
    {
      name: "Cobertura em Policarbonato Compacto",
      gallery: "coberturaPolicarbonatoCompacto",
    },
    {
      name: "Cobertura Reta em Policarbonato Alveolar",
      gallery: "coberturaRetaPolicarbonatoAlveolar",
    },
    {
      name: "Cobertura Retrátil",
      gallery: "coberturaRetratil",
    },
    {
      name: "Cobertura em Vidro",
      gallery: "coberturaVidro",
    },
    {
      name: "Cortina Rolô",
      gallery: "cortinaRolo",
    },
    {
      name: "Painel em ACM",
      gallery: "painelAcm",
    },
    {
      name: "Tenda",
      gallery: "tenda",
    },
    {
      name: "Teto Abre e Fecha",
      gallery: "tetoAbreFecha",
    },
    {
      name: "Toldo Articulado",
      gallery: "toldoArticulado",
    },
    {
      name: "Toldo Fixo em Lona",
      gallery: "toldoFixoLona",
    },
    {
      name: "Toldo em Policarbonato Alveolar",
      gallery: "toldoPolicarbonatoAlveolar",
    },
    {
      name: "Toldo Retrátil",
      gallery: "toldoRetratil",
    },
    {
      name: "Toldo de Trilho",
      gallery: "toldoTrilho",
    },
    {
      name: "Projetos Personalizados",
      gallery: "projetoPersonalizado",
    },
  ]

  const showGallery = (galleryName, galleryLabel) => {
    setGalleryActive(galleryName)
    setGalleryActiveLabel(galleryLabel)
    window.scroll(0, galleryRef.current.offsetTop)
  }

  return (
    <div className="service-list">
      {services.map((service, index) => {
        return (
          <div key={service.name} className="service">
            <div className="service-icon">
              <img
                src={`service-icons/${service.gallery}.svg`}
                alt={service.name}
              />
            </div>
            <div className="service-name">{service.name}</div>
            <button
              type="button"
              onClick={() => showGallery(service.gallery, service.name)}
              className="service-gallery-link"
            >
              Galeria de Fotos
            </button>
          </div>
        )
      })}
    </div>
  )
}
