import React from "react"
import Title from "./title"

export default function about() {
  return (
    <section id="sobre-nos" className="about section section-white">
      <div className="container">
        <div className="columns">
          <div className="column has-text-centered">
            <Title
              label="MIRANDA TOLDOS"
              pos="Tradição e Qualidade"
              theme="dark"
            />
          </div>
        </div>

        <div className="columns is-centered">
          <div className="column is-half-widescreen">
            <p>
              A Miranda Toldos conta com as melhores soluções em proteção para
              áreas externas residenciais e comercias, com atendimento para
              capital, litoral e interior de São Paulo.
            </p>
            <p>
              Comandada por André Miranda desde 2005, já mantém tradição no
              mercado desde 1976, quando foi fundada por seu avô. Desde então,
              passada de pai para filho, segue evoluindo seus trabalhos e
              garantindo qualidade e profissionalismo aos seus clientes.
            </p>
            <p>Conheça nossos serviços e faça um orçamento sem compromisso!</p>
          </div>
        </div>
      </div>
    </section>
  )
}
