import React, { useContext, useState, useCallback, useEffect } from "react"
import Title from "./title"
import { useStaticQuery, graphql } from "gatsby"

import IndexContext from "../components/context"

import Img from "gatsby-image/withIEPolyfill"

import Slider from "react-slick"

export default function Gallery() {
  const { galleryActive, galleryRef, galleryActiveLabel } = useContext(
    IndexContext
  )

  const [activeImageIndex, setActiveImageIndex] = useState(0)

  const galleries = useCallback(
    useStaticQuery(graphql`
      fragment largeImage on File {
        childImageSharp {
          fluid(quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      query {
        coberturaPolicarbonatoCompacto: allFile(
          filter: {
            relativeDirectory: {
              eq: "galleries/cobertura-policarbonato-compacto"
            }
          }
          sort: { fields: name }
        ) {
          edges {
            node {
              ...largeImage
            }
          }
        }

        coberturaRetaPolicarbonatoAlveolar: allFile(
          filter: {
            relativeDirectory: {
              eq: "galleries/cobertura-reta-policarbonato-alveolar"
            }
          }
          sort: { fields: name }
        ) {
          edges {
            node {
              ...largeImage
            }
          }
        }

        coberturaRetratil: allFile(
          filter: { relativeDirectory: { eq: "galleries/cobertura-retratil" } }
          sort: { fields: name }
        ) {
          edges {
            node {
              ...largeImage
            }
          }
        }

        coberturaVidro: allFile(
          filter: { relativeDirectory: { eq: "galleries/cobertura-vidro" } }
          sort: { fields: name }
        ) {
          edges {
            node {
              ...largeImage
            }
          }
        }

        cortinaRolo: allFile(
          filter: { relativeDirectory: { eq: "galleries/cortina-rolo" } }
          sort: { fields: name }
        ) {
          edges {
            node {
              ...largeImage
            }
          }
        }

        painelAcm: allFile(
          filter: { relativeDirectory: { eq: "galleries/painel-acm" } }
          sort: { fields: name }
        ) {
          edges {
            node {
              ...largeImage
            }
          }
        }

        projetoPersonalizado: allFile(
          filter: {
            relativeDirectory: { eq: "galleries/projeto-personalizado" }
          }
          sort: { fields: name }
        ) {
          edges {
            node {
              ...largeImage
            }
          }
        }

        tenda: allFile(
          filter: { relativeDirectory: { eq: "galleries/tenda" } }
          sort: { fields: name }
        ) {
          edges {
            node {
              ...largeImage
            }
          }
        }

        tetoAbreFecha: allFile(
          filter: { relativeDirectory: { eq: "galleries/teto-abre-fecha" } }
          sort: { fields: name }
        ) {
          edges {
            node {
              ...largeImage
            }
          }
        }

        toldoArticulado: allFile(
          filter: { relativeDirectory: { eq: "galleries/toldo-articulado" } }
          sort: { fields: name }
        ) {
          edges {
            node {
              ...largeImage
            }
          }
        }

        toldoFixoLona: allFile(
          filter: { relativeDirectory: { eq: "galleries/toldo-fixo-lona" } }
          sort: { fields: name }
        ) {
          edges {
            node {
              ...largeImage
            }
          }
        }

        toldoPolicarbonatoAlveolar: allFile(
          filter: {
            relativeDirectory: { eq: "galleries/toldo-policarbonato-alveolar" }
          }
          sort: { fields: name }
        ) {
          edges {
            node {
              ...largeImage
            }
          }
        }

        toldoRetratil: allFile(
          filter: { relativeDirectory: { eq: "galleries/toldo-retratil" } }
          sort: { fields: name }
        ) {
          edges {
            node {
              ...largeImage
            }
          }
        }

        toldoTrilho: allFile(
          filter: { relativeDirectory: { eq: "galleries/toldo-trilho" } }
          sort: { fields: name }
        ) {
          edges {
            node {
              ...largeImage
            }
          }
        }
      }
    `),
    []
  )

  const galleryResponsive = [
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
      },
    },
  ]

  useEffect(() => {
    setActiveImageIndex(0)
  }, [galleryActive])

  const setupThumb = (fluid, index) => (
    <div onClick={() => setActiveImageIndex(index)} className="gallery-thumb">
      <Img fluid={fluid} objectFit="cover" style={{ paddingRight: 5 }} />
    </div>
  )

  const setUpMainImage = fluid => <Img fluid={fluid} objectFit="contain" />

  return (
    <section ref={galleryRef} className="gallery section section-white">
      <div className="container">
        <div className="columns">
          <div className="column has-text-centered">
            <Title
              label="Galeria de Fotos"
              pos={galleryActiveLabel}
              theme="dark"
            />
          </div>
        </div>

        <div className="columns is-multiline is-centered">
          <div className="column is-10-widescreen is-full">
            <div>
              {galleries[galleryActive] && (
                <div className="gallery-main-image">
                  {setUpMainImage(
                    galleries[galleryActive].edges[activeImageIndex].node
                      .childImageSharp.fluid
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="column is-10">
            <div className="gallery-thumb-list">
              {galleries[galleryActive] && (
                <Slider
                  dots={false}
                  infinite={false}
                  responsive={galleryResponsive}
                  slidesToShow={5}
                  slidesToScroll={5}
                >
                  {galleries[galleryActive].edges.map((image, index) =>
                    setupThumb(image.node.childImageSharp.fluid, index)
                  )}
                </Slider>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
