import React, { useState } from "react"
import { FaWhatsapp } from "react-icons/fa"

import PropTypes from "prop-types"
import ReCAPTCHA from "react-google-recaptcha"
import { Container, Header, Link } from "./styles"

export default function Whatsapp({ status }) {
  const [captchaVerified, setCaptchaVerified] = useState(false) // State para o reCAPTCHA
  const [formResult, setFormResult] = useState({
    show: false,
    type: null,
    message: null,
  })
  const onCaptchaChange = value => {
    if (value) {
      setCaptchaVerified(true) // Marca o reCAPTCHA como verificado
    } else {
      setCaptchaVerified(false) // Se o reCAPTCHA for inválido
    }
  }
  return (
    <Container status={status}>
      <Header>Atendimento por WhatsApp!</Header>

      <div>
        <Link
          href="https://wa.me/5511998422797?text=Olá, gostaria de falar com um consultor"
          target="_blank"
          rel="noopener noreferrer"
          className="link"
          onClick={e => {
            if (!captchaVerified) {
              e.preventDefault()
              setFormResult({
                show: true,
                type: "danger",
                message: "Por favor, verifique o reCAPTCHA.",
              })
            } else
              window.gtag("event", "conversion", {
                send_to: "AW-606920003/x4W0CKKAk8oZEMO6s6EC",
                value: 1.0,
                currency: "BRL",
              })
          }}
        >
          <FaWhatsapp />
          Fale Agora com o Consultor
        </Link>
      </div>
      <div className="field">
        <ReCAPTCHA
          sitekey="6Lf2UEYqAAAAAPu0lFsyiqEbVv6ycc6ORHsxzuCW" // Coloque sua chave do site aqui
          onChange={onCaptchaChange}
        />
      </div>
      {formResult.show && (
        <div
          className={`notification is-${
            formResult.type === "error" ? "danger" : "success"
          }`}
        >
          <button
            class="delete"
            onClick={() =>
              setFormResult({ show: false, type: null, message: null })
            }
          ></button>
          {formResult.message}
        </div>
      )}
    </Container>
  )
}

Whatsapp.propTypes = {
  status: PropTypes.bool.isRequired,
}

Whatsapp.propDefault = {
  status: false,
}
