import React from "react"
import Title from "./title"
import { FaEnvelope, FaMapMarkedAlt } from "react-icons/fa"
import HeaderContacts from "./headerContacts"
import Form from "./form"

export default function Contact() {
  return (
    <section id="contato" className="contact section section-blue">
      <div className="container">
        <div className="columns">
          <div className="column has-text-centered">
            <Title pre="Entre em" label="Contato" theme="light" />
          </div>
        </div>

        <div className="columns is-centered">
          <div className="column is-half-widescreen">
            <p>
              Deseja conhecer melhor nossos serviços e produtos ou fazer um
              orçamento? Entre em contato pelo formulário e nossa equipe
              retornará em breve!
            </p>
            <p>
              Se preferir, entre em contato conosco por telefone ou WhatsApp.
            </p>
          </div>
        </div>

        <div className="columns is-centered is-multiline">
          <div className="column is-one-third-widescreen is-full">
            <HeaderContacts footer />
            <a
              href="mailto:mirandatoldos@yahoo.com.br"
              rel="noreferrer noopener"
              target="_blank"
              className="contact-item"
            >
              <FaEnvelope />
              mirandatoldos@yahoo.com.br
            </a>

            <div className="contact-item">
              <FaMapMarkedAlt />
              <div className="address">
                <strong>Rua Celso de Almeida Sena, 7A</strong>
                Vila Dalila - São Paulo/SP
              </div>
            </div>

            <div className="map-container">
              <iframe
                title="Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3657.529726598808!2d-46.52365928454682!3d-23.549410984688834!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce5dfdd313a05d%3A0xd654a655124c3325!2sRua%20Celso%20de%20Almeida%20Sena%2C%207%20-%20Vila%20Dalila%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2003522-020!5e0!3m2!1spt-BR!2sbr!4v1590339865959!5m2!1spt-BR!2sbr"
                width="400"
                height="300"
                frameborder="0"
                allowfullscreen=""
                aria-hidden="false"
              ></iframe>
            </div>
          </div>

          <div className="column is-one-third-widescreen is-full">
            <Form />
          </div>
        </div>
      </div>
    </section>
  )
}
