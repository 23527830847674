import React from "react"
import Title from "./title"

import ServiceList from "./serviceList"

export default function Services() {
  return (
    <section id="servicos" className="services">
      <div className="container">
        <div className="columns">
          <div className="column has-text-centered">
            <Title label="Serviços" pre="Nossos" />
          </div>
        </div>

        <div className="columns is-centered">
          <div className="column is-half has-text-centered">
            Desenvolvemos projetos personalizados, comerciais e residenciais,
            trazendo as soluções ideais para cada ambiente e necessidade.
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <ServiceList />
          </div>
        </div>
      </div>
    </section>
  )
}
